module.exports={
  "Loading": "Loading",
  "Back": "Back",
  "Contact us": "Contact us",
  "Letâs solve a problem": "Letâs solve a problem",
  "Â© 2019 Zoe Kelly": "Â© 2019 Zoe Kelly",
  "All Rights Reserved.": "All Rights Reserved.",
  "This website is open-source.": "This website is open-source.",
  "More": "More",
  "Case studies": "Case studies",
  "Zoe Kelly": "Zoe Kelly",
  "Selected cases": "Selected cases",
  "Oops": "Oops",
  "We apologize, an error has occured on our site. It may be temporary and you could": "We apologize, an error has occured on our site. It may be temporary and you could",
  "try again": "try again",
  "or go back to": "or go back to",
  "the homepage": "the homepage",
  "There is no page at this address. Try finding your way using the menu or from": "There is no page at this address. Try finding your way using the menu or from",
  "About & team": "About & team",
  "About us": "About us",
  "Team": "Team",
  "Cases": "Cases",
  "About": "About",
  "Close": "Close",
  "Index": "Index",
  "Weâre always looking for talents": "Weâre always looking for talents",
  "Talk to Anton, heâs nice.": "Talk to Anton, heâs nice.",
  "Want to work with us?": "Want to work with us?",
  "Let us know :)": "Let us know :)",
  "Let us know!": "Let us know!",
  "Recent projects": "Recent projects",
  "Some recent projects": "Some recent projects",
  "Some recent projects:": "Some recent projects:",
  "Recent projects:": "Recent projects:",
  "â¡": "â¡",
  "â¦": "â¦",
  "it's Zoeâs": "it's Zoeâs",
  "â": "â",
  "[ âsite.â, âdesign.â , âillustration.â, âcode.â, âbranding.â, âsite.â ]": "[ âsite.â, âdesign.â , âillustration.â, âcode.â, âbranding.â, âsite.â ]",
  "It's Zoe's": "It's Zoe's",
  "It's Zoe's site": "It's Zoe's site",
  "": "",
  "A front-end developer gone UX": "A front-end developer gone UX",
  "â Zoe Kelly": "â Zoe Kelly",
  "* UX designer with a passion for ethical design": "* UX designer with a passion for ethical design",
  "It's": "It's",
  "It's Zoe": "It's Zoe",
  "*UX designer with a passion for ethical design": "*UX designer with a passion for ethical design",
  "...": "...",
  "ð©âð» Zoe Kelly": "ð©âð» Zoe Kelly",
  "Asbout": "Asbout",
  "ð©âð» Zoe!": "ð©âð» Zoe!",
  "*I'm Zoe, a UX designer with a passion for ethical design": "*I'm Zoe, a UX designer with a passion for ethical design",
  "About..": "About..",
  "ð  Zoe!": "ð  Zoe!",
  "About me..": "About me..",
  "I'm Zoe Kelly, a Stockholm-based UX designer with a passion for ethical design": "I'm Zoe Kelly, a Stockholm-based UX designer with a passion for ethical design",
  "I'm Zoe Kelly, my UX practice is centred around empathy, evidence and mischeif": "I'm Zoe Kelly, my UX practice is centred around empathy, evidence and mischeif",
  "I'm Zoe Kelly, my UX practice is centered around empathy, evidence and mischeif": "I'm Zoe Kelly, my UX practice is centered around empathy, evidence and mischeif",
  "I'm Zoe Kelly, I work with UX that is empathetic, ethical, evidence-based and playful": "I'm Zoe Kelly, I work with UX that is empathetic, ethical, evidence-based and playful",
  "I'm Zoe Kelly, <br>I work with UX that is empathetic, ethical, evidence-based and playful": "I'm Zoe Kelly, <br>I work with UX that is empathetic, ethical, evidence-based and playful",
  "I'm Zoe Kelly, \n I work with UX that is empathetic, ethical, evidence-based and playful": "I'm Zoe Kelly, \n I work with UX that is empathetic, ethical, evidence-based and playful",
  "I'm Zoe Kelly,\n          I work with UX that is empathetic, ethical, evidence-based and playful": "I'm Zoe Kelly,\n          I work with UX that is empathetic, ethical, evidence-based and playful",
  "I'm Zoe Kelly I work with UX that is empathetic, ethical, evidence-based and playful": "I'm Zoe Kelly I work with UX that is empathetic, ethical, evidence-based and playful",
  "I'm Zoe Kelly + I work with UX that is empathetic, ethical, evidence-based and playful": "I'm Zoe Kelly + I work with UX that is empathetic, ethical, evidence-based and playful",
  "I'm Zoe Kelly, I like my UX work to be empathetic, ethical, evidence-based and playful": "I'm Zoe Kelly, I like my UX work to be empathetic, ethical, evidence-based and playful",
  "I'm Zoe Kelly, I like my UX work to be empathetic, ethical, evidence-based + playful": "I'm Zoe Kelly, I like my UX work to be empathetic, ethical, evidence-based + playful",
  "I'm Zoe Kelly, I like my UX work to be: empathetic, ethical, evidence-based + playful": "I'm Zoe Kelly, I like my UX work to be: empathetic, ethical, evidence-based + playful",
  "I'm Zoe Kelly, \n I like my UX work to be: empathetic, ethical, evidence-based + playful": "I'm Zoe Kelly, \n I like my UX work to be: empathetic, ethical, evidence-based + playful",
  "I'm Zoe Kelly, <br/> I like my UX work to be: empathetic, ethical, evidence-based + playful": "I'm Zoe Kelly, <br/> I like my UX work to be: empathetic, ethical, evidence-based + playful",
  "\nI'm Zoe Kelly, \n I like my UX work to be: empathetic, ethical, evidence-based + playful": "\nI'm Zoe Kelly, \n I like my UX work to be: empathetic, ethical, evidence-based + playful",
  "I'm Zoe Kelly, \n I like my UX work to be: empathetic, ethical, evidence-based + playful\n": "I'm Zoe Kelly, \n I like my UX work to be: empathetic, ethical, evidence-based + playful\n",
  "I'm Zoe Kelly,\n          I like my UX work to be: empathetic, ethical, evidence-based + playful": "I'm Zoe Kelly,\n          I like my UX work to be: empathetic, ethical, evidence-based + playful",
  "I'm Zoe Kelly & I live in Stockholm,\n          I like my UX work to be: empathetic, ethical, evidence-based + playful": "I'm Zoe Kelly & I live in Stockholm,\n          I like my UX work to be: empathetic, ethical, evidence-based + playful",
  "I'm Zoe Kelly\n          I like my UX work to be: empathetic, ethical, evidence-based + playful": "I'm Zoe Kelly\n          I like my UX work to be: empathetic, ethical, evidence-based + playful",
  "I'm Zoe Kelly,\n          I believe in design as a force for good": "I'm Zoe Kelly,\n          I believe in design as a force for good",
  "I believe in design as a force for good.": "I believe in design as a force for good.",
  "My name is Zoe Kelly": "My name is Zoe Kelly",
  "My name is Zoe Kelly.": "My name is Zoe Kelly.",
  "I believe in design as a force for good & empathy in UX.": "I believe in design as a force for good & empathy in UX.",
  "My name is Zoe Kelly. I believe in design as a force for good &": "My name is Zoe Kelly. I believe in design as a force for good &",
  "empathy": "empathy",
  "in UX.": "in UX.",
  "My name is Zoe Kelly. I believe in design as a force for": "My name is Zoe Kelly. I believe in design as a force for",
  "good": "good",
  "&": "&",
  ".": ".",
  "(I'm Zoe Kelly. I believe in design as a force for": "(I'm Zoe Kelly. I believe in design as a force for",
  "in UX.)": "in UX.)",
  "(I'm Zoe Kelly, I work with UX in Stockholm. I believe in design as a force for": "(I'm Zoe Kelly, I work with UX in Stockholm. I believe in design as a force for",
  "(I'm Zoe Kelly and I work with UX in Stockholm. I believe design should be": "(I'm Zoe Kelly and I work with UX in Stockholm. I believe design should be",
  "humane": "humane",
  "empathetic": "empathetic",
  "(I'm Zoe Kelly and I work with UX in Stockholm. I believe in design that is inherently": "(I'm Zoe Kelly and I work with UX in Stockholm. I believe in design that is inherently",
  ")": ")",
  "( I'm Zoe Kelly and I work with UX in Stockholm. I believe in design that is inherently": "( I'm Zoe Kelly and I work with UX in Stockholm. I believe in design that is inherently",
  ".)": ".)",
  " I'm Zoe Kelly and I work with UX in Stockholm. I believe in design that is inherently": " I'm Zoe Kelly and I work with UX in Stockholm. I believe in design that is inherently",
  "by nature)": "by nature)",
  "(I'm Zoe Kelly and I work with UX in Stockholm. I believe in design that is ": "(I'm Zoe Kelly and I work with UX in Stockholm. I believe in design that is ",
  "(I'm Zoe Kelly and I work with UX in Stockholm. I believe in design that is": "(I'm Zoe Kelly and I work with UX in Stockholm. I believe in design that is",
  "(I'm Zoe Kelly and I work with UX in Stockholm. I believe in work that is": "(I'm Zoe Kelly and I work with UX in Stockholm. I believe in work that is",
  "by design.)": "by design.)",
  "(I'm": "(I'm",
  "and I work with UX in Stockholm. I believe in work that is": "and I work with UX in Stockholm. I believe in work that is"
}